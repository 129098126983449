import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import ProfileImage from "../components/profile-image"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}))

const Info = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Info" />
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <ProfileImage />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h3" gutterBottom>
              About me
            </Typography>
            <Typography paragraph>
              When I was young, I knew that in addition to being interested in
              technology, I wanted to do something that had the potential to
              positively impact the lives of as many people as possible. This
              led me to pursue a degree in Software Engineering from the{" "}
              <a href="https://www.uvic.ca/">University of Victoria</a> where I
              realized that I was drawn to learning deeply about users and
              collaborating with others to build products and experiences that
              filled customers' unmet needs.
            </Typography>
            <Typography paragraph>
              While I had learned what I was passionate about, I didn't hear
              about the program/product manager role until a recruiter at
              Microsoft recommended that I interview for an internship as a PM
              in 2013. I started as an intern on the Internet Explorer 11 team
              that summer and fell in love with the role immediately. Following
              another internship on the team in 2014, I joined the Microsoft
              Edge team full time in the fall of 2015 and quickly fell in love with 
              the PM role.
            </Typography>
            <Typography paragraph>
              Since then I've been fortunate enough to meet a top user request by bringing
              the first browser extensions to Microsoft Edge; improve developer productivity by reducing the 
              amount of unactionable feedback filed by users; maintain a key differentiator for 
              Edge by landing 4K Netflix support following our replatform to Chromium; solve user problems in ways that 
              worked for the business by working on privacy-related features such as <a href="https://blogs.windows.com/msedgedev/2019/12/03/improving-tracking-prevention-microsoft-edge-79/">Tracking Prevention</a>,
              {" "}<a href="https://support.microsoft.com/en-us/microsoft-edge/use-password-monitor-to-help-protect-your-passwords-in-microsoft-edge-f3c7a92d-25d1-ca84-6ab2-637032e4fe56">Password Monitor</a>,
              and the <a href="https://techcommunity.microsoft.com/t5/articles/introducing-transparent-ads-in-microsoft-edge-preview/td-p/3035970"> Transparent Ad Providers program</a>; 
              and most recently, grow an incredible PM team that is focused on delighting Edge users everywhere and 
              empowering web developers to achieve their vision on the web.
            </Typography>
            <Typography paragraph>
              I'm also passionate about improving team culture and paying
              forward all the support I've received since starting my career. In
              2017, I started Edge Squad, a diverse and inclusive community for
              early-in-career talent that has boosted the satisfaction of new
              hires on the team by growing the size of their social and
              professional networks, offering training opportunities, and
              creating a sense of belonging.
            </Typography>
            <Typography paragraph>
              I'm currently a Principal Product Manager on the Microsoft Viva team working 
              with the <a href="https://www.glintinc.com">Glint</a> team to bring feedback 
              and action into the flow of work, helping leaders at all levels support 
              peoples' happiness, well-being, and success. 
            </Typography>
            <Typography paragraph>
              If you'd like to chat, please feel free to connect with me on any
              of my social media accounts or send me a mail at{" "}
              <a href="mailto:%73cott@scott%2D%6Cow%2E%63%6Fm">scott@scott-low.com</a>.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default Info
